import axios from "axios";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import React, { useState } from "react";
import "../assets/css/menuQuestion.css";
import "../assets/fontFamily/square-721-bold-extended-bt-webfont.woff";
import sound from "../assets/mp3/EmoSpark_Init.mp3";
import myQuestions from "../utils/data";
import AudioEmotion from "./AudioEmotion";

import CarQuestionForm from "./CarQuestionForm";
import Loader from "./Loader";
import ProgressBar from "./ProgressBar";
import QuizOptionButton from "./QuizOptionButton";
import SecretInputPage from "./SecretInputPage";
import ValidationError from "./ValidationError";
import Video from "./Video";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let recognizerLocal;
let count = 0;
let myTimeout;
let text = "";
let updatedText = "";

function getQueryParams(theParameter) {
  var params = window.location.search.substr(1).split("&");
  for (var i = 0; i < params.length; i++) {
    var p = params[i].split("=");
    if (p[0] == theParameter) return decodeURIComponent(p[1]);
  }
  return false;
}

export default function VoiceQuestion(props) {
  const [btnWaiting, setBtnWaiting] = useState(false);
  const [btnState, setBtnState] = useState(true);
  const [audioEmotion, setAudioEmotion] = useState();
  const [audioEmotionForIndex1, setAudioEmotionForIndex1] = useState();
  const [epuId, setEpuId] = useState("");
  const [answer1, setAnswer1] = useState("");
  const [reply, setReply] = useState("");
  const [txtAnswer, setTxtAnswer] = useState("");
  const [lastMessage, setLastMessage] = useState("");
  const [isValidAnswer, setIsValidAnswer] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(-1);
  const [lableText, setLableText] = useState(myQuestions[questionIndex]);
  const [answer, setAnswer] = useState({});
  const [speechCount, setSpeechCount] = useState(0);
  const [waiting, setWaiting] = useState(false);
  const [secretBox, setSecretBox] = useState(
    getQueryParams("s") ? true : false
  );
  const [selectedMode, setSelectedMode] = useState(-1);
  const [showQuestion, setShowQuestion] = useState(false)
  const [secret, setSecret] = useState(
    getQueryParams("s") ? getQueryParams("s") : ""
  );
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [recordFlag, setRecordFlag] = useState(false);
  const [noAws, setNoAws] = useState(false);
  const [question, setQuestion] = useState("");
  const [stopbtn, setStopbtn] = useState(false);
  const [progresstext, setProgress] = useState("");
  const [question0, setQuestion0] = useState(false);
  const [audioRunning, setAudioRunning] = useState(false);

  //--------------------------------------------Recognize--------------------------------------------------------------------//

  const recognize = async () => {
    try {
      setBtnWaiting(true);
      let chunks = [];
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      let recorder = new MediaRecorder(stream);

      const speechConfig = sdk.SpeechTranslationConfig.fromSubscription(
        process.env.REACT_APP_SUBSCRIPTION_KEY,
        process.env.REACT_APP_REGION
      );
      speechConfig.speechRecognitionLanguage = "en-US";
      let language = "en";
      speechConfig.addTargetLanguage(language);
      var audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
      recognizerLocal = new sdk.TranslationRecognizer(
        speechConfig,
        audioConfig
      );
      recognizerLocal.recognizing = (_, event) => {
        if (event.result.reason == sdk.ResultReason.TranslatingSpeech) {
          text = updatedText + event.result.text;
          setProgress(text);

          if (
            questionIndex == 0 &&
            text.split(" " || "," || "." || "?").length > 99
          ) {
            stopRecognize();
          }
          if (
            questionIndex == 1 &&
            text.split(" " || "," || "." || "?").length > 499
          ) {
            stopRecognize();
          }
        }
      };
      recognizerLocal.recognized = (_, event) => {
        if (event.result.reason == sdk.ResultReason.TranslatedSpeech) {
          updatedText = updatedText + event.result.text;

          setProgress(updatedText);

          if (
            questionIndex == 0 &&
            updatedText.split(" " || "," || "." || "?").length > 99
          ) {
            stopRecognize();
          }
          if (
            questionIndex == 1 &&
            updatedText.split(" " || "," || "." || "?").length > 499
          ) {
            stopRecognize();
          }
        }
      };
      recognizerLocal.sessionStarted = (_, event) => {
        recorder.start();
        setBtnState(false);
        setBtnWaiting(false);
      };
      recognizerLocal.sessionStopped = async (_, event) => {
        setWaiting(true);
        setSpeechCount(speechCount + 1);
        if (updatedText) {
          await (recorder.ondataavailable = async (e) => {
            chunks.push(e.data);
            let blob = new Blob(chunks, { type: "audio/wav" });
            let newFile = new File([blob], "newAudio.wav", {
              type: blob.type,
              lastModified: new Date().getTime(),
            });
            let formData = new FormData();
            formData.append("attachment", newFile);
            formData.append("secret", secret);

            let audioResponse = await axios.post(
              `${process.env.REACT_APP_END_POINT}/uploadAudio`,
              formData
            );
            if (audioResponse.status == 200) {
              setAudioEmotion(audioResponse.data.data);
              if (questionIndex === 0) {
                setAudioEmotionForIndex1(audioResponse.data.data);
              }
              setWaiting(false);
            } else {
              setWaiting(false);
            }
            setWaiting(false);
          });

          //   let response = await axios.post(
          //     process.env.REACT_APP_MODERATOR_API,
          //     updatedText,
          //     {
          //       headers: {
          //         "Content-Type": "text/plain",
          //         "Ocp-Apim-Subscription-Key":
          //           process.env.REACT_APP_OCA_SUBSCRIPTION_KEY,
          //       },
          //     }
          //   );
        } else {
          setWaiting(false);
        }
        if (
          (questionIndex == 0 &&
            updatedText.split(" " || "," || "." || "?").length >= 25) ||
          (questionIndex == 1 &&
            updatedText.split(" " || "," || "." || "?").length >= 150)
        ) {
          setIsValidAnswer(true);
        } else {
          setIsValidAnswer(false);
        }
        if (updatedText.length > 0) {
          setNoAws(false);
        }
        console.log("updatedText", updatedText);
        setAnswer1(updatedText);
        // submitApi(updatedText);
        recorder.stop();
        recognizerLocal.stopContinuousRecognitionAsync(
          () => {
            recognizerLocal.close();
            recognizerLocal = undefined;
          },
          function (err) {
            recognizerLocal.close();
            recognizerLocal = undefined;
          }
        );
      };
      startRecognition(recognizerLocal);
    } catch (err) {
      setWaiting(false);
      setIsValidAnswer(false);
      console.log(err);
    }
  };
  //--------------------------------------------Recognize--------------------------------------------------------------------//

  //--------------------------------------------Recognise function--------------------------------------------------------------//

  const stopRecognize = () => {
    setRecordFlag(false);
    props.setSpeedUp(false);
    setBtnState(true);

    if (!!recognizerLocal) {
      recognizerLocal.stopContinuousRecognitionAsync(
        () => { },
        function (err) {
          recognizerLocal.close();
          recognizerLocal = undefined;
        }
      );
    }
  };

  const startRecognition = (r) => {
    if (!!r) {
      r.startContinuousRecognitionAsync(undefined, function (err) {
        r.close();
        r = undefined;
      });
    }
  };

  //--------------------------------------------Recognise function--------------------------------------------------------------//

  const handleOnClick = async (ansIndex) => {
    if (questionIndex == myQuestions.length - 1) {
      // setLastMessage("Thankyou!");
      let answerObj = answer;
      answerObj[questionIndex + 1] = ansIndex == 1 ? "T" : "A";
      setAnswer(answerObj);
      let answerData = Object.values(answerObj);
      let personalityResponse = await axios.post(
        `${process.env.REACT_APP_END_POINT}/get_personality`,
        {
          epuid: epuId,
          persona: `XXXX-X`,
          description: description,
          secret: secret
        }
      );
      if (personalityResponse.status == 200) {
        window.open(
          `https://metasoul.live/soulViewer.min.html?secret=${secret}&epuId=${epuId}&persona=${answerData.filter((ans) => ans == "T").length > 1 ? "T" : "A"
          }`,
          "_self"
        );
      }
    } else {
      setQuestionIndex(questionIndex + 1);
      setLableText(myQuestions[questionIndex + 1]);
      let answerObj = answer;
      answerObj[questionIndex + 1] = ansIndex == 1 ? "T" : "A";
      setAnswer(answerObj);
    }
  };

  const submitApi = async (recordedText) => {
    props.setSpeedUp(false);
    setWaiting(true);
    setAudioEmotion("");
    setSpeechCount(0);
    // if (questionIndex == 0) {
    // let text = txtAnswer.concat(answer1);
    let response = await axios.post(
      `${process.env.REACT_APP_END_POINT}/predict`,
      { text: recordedText },
      { headers: { "Content-Type": "application/json" } }
    );
    if (response.status == 200) {
      // text = "";
      // updatedText = "";
      setProgress("");

      setReply(response.data.data.reply);
      setAnswer1("");
      setTxtAnswer("");
      setQuestionIndex(questionIndex + 2);
      setLableText(myQuestions[questionIndex + 2]);
      setWaiting(false);
    }
    // } else {

    setDescription(recordedText);

    //   setTxtAnswer(txtAnswer.concat(answer1));
    //   text = "";
    //   updatedText = "";
    //   setProgress("");
    //   setAnswer1("");

    //   setQuestionIndex(questionIndex + 2);
    //   setLableText(myQuestions[questionIndex + 2]);
    // }
    setIsValidAnswer(false);
    setWaiting(false);
  };

  //----------------------------------------handleSecret-------------------------------------------------------------//

  const handlesecret = async () => {
    try {
      setError("");
      if (secret) {
        setWaiting(true);
        let response = await axios.post(
          `${process.env.REACT_APP_END_POINT}/get_epu_list`,
          { secret: secret }
        );
        if (
          response.status == 200 &&
          response.data?.data?.[0]?.available_cloud_epu_list?.length
        ) {
          if (response?.data?.epuid?.length > 0) {
            setEpuId(response.data.epuid[0]);
            setQuestionIndex(0);
            setLableText(myQuestions[0]);
            setWaiting(false);
            props.setPoints(true);
            setSelectedMode(0);
            setShowQuestion(true);
            // document.getElementById("at4-share").style.display = "none";
          } else {
            window.open(
              `https://metasoul.live/soulViewer.min.html?secret=${secret}&epuId=${response.data.data[0].available_cloud_epu_list[0]}`
            );
            setWaiting(false);
          }
        } else {
          window.open(" https://emoshape.com/product/metasoul/");
          setError("sorry you have no MetaSoul® in a cocoon");
          setWaiting(false);
        }
      } else {
        window.open(" https://emoshape.com/product/metasoul/");
        setError("please Pass Secret");
      }
    } catch (error) { }
  };

  //----------------------------------------handleSecret-------------------------------------------------------------//

  //-----------------------------------------Delay Timer---------------------------------------------------------------//

  React.useEffect(() => {
    if (lableText && Array.isArray(lableText.que) && recordFlag) {
      if (count < lableText.que.length - 1 && recordFlag) {
        handleQuestion();
      } else {
        setStopbtn(false);
      }
    } else {
      count = 0;
      setStopbtn(false);
      setQuestion(lableText ? lableText.que[0] : "");
    }
  }, [lableText, question, recordFlag]);

  const handleQuestion = async () => {
    setStopbtn(true);
    await delay(15000);
    count = count + 1;
    setQuestion(lableText.que[count]);
  };

  function myTimer() {
    if (updatedText.length === 0) {
      setNoAws(true);
    }
    stopRecognize();
  }

  React.useEffect(() => {
    if (questionIndex === 0 && selectedMode === 2) {
      sparkQuestion();
    }
  }, [questionIndex, selectedMode]);

  const sparkQuestion = async () => {
    await delay(5000);
    setQuestion0(true);
    setAudioRunning(true);
    var audio = new Audio(sound);
    audio.play();
    audio.addEventListener("ended", function () {
      setAudioRunning(false);
    });
  };

  // useEffect(() => {
  //   if (lastMessage && audioEmotionForIndex1) {
  //     customwaveFunction();
  //   }
  // }, [lastMessage]);

  const customwaveFunction = async () => {
    try {
      if (audioEmotionForIndex1 && Object.keys(audioEmotionForIndex1).length) {
        for (const emotion of Object.keys(audioEmotionForIndex1)) {
          const data = {
            type: emotion,
            level: audioEmotionForIndex1[emotion],
          };

          if (emotion === "Anger") {
            audioEmotionApi(data);
            await delay(5000);
          } else if (emotion === "Disgust") {
            audioEmotionApi(data);
            await delay(5000);
          } else if (emotion === "Disgust") {
            audioEmotionApi(data);
            await delay(5000);
          } else if (emotion === "Fear") {
            audioEmotionApi(data);
            await delay(5000);
          } else if (emotion === "Joy") {
            data.type = "Happy";
            audioEmotionApi(data);
            await delay(5000);
          } else if (emotion === "Sadness") {
            data.type = "Sad";
            audioEmotionApi(data);
            await delay(5000);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const audioEmotionApi = async (data) => {
    try {
      let response = await axios.put(`https://www.emohuman.com/custom_wave`, {
        secret: secret,
        epuid: epuId,
        type: data.type,
        level: data.level,
        duration: 20,
        origin: 0,
        apex: 2,
        curve: 3,
      });
      return response;
    } catch (err) {
      console.log("err", err);
    }
  };

  //-----------------------------------------Delay Timer---------------------------------------------------------------//

  function progress() {
    let updatedTextAnswer = progresstext;
    let totalWordCount =
      questionIndex == 0
        ? updatedTextAnswer.split(" " || "," || "." || "?").length > 100
          ? 100
          : updatedTextAnswer.split(" " || "," || "." || "?").length
        : updatedTextAnswer.split(" " || "," || "." || "?").length > 500
          ? 500
          : updatedTextAnswer.split(" " || "," || "." || "?").length;
    let percentage =
      questionIndex == 0
        ? (totalWordCount * 100) / 100
        : (totalWordCount * 100) / 500;

    return (
      <ProgressBar percentage={percentage} questionIndex={questionIndex} />
    );
  }

  function loader() {
    return <span class="loading">Authorize The Mic</span>;
  }

  return (
    <div className="App">
      {waiting && <Loader />}
      {questionIndex === -1 &&
        (!secretBox ? (
          <div className="secretwrap">
            <div className="secretTextTitle">
              <div className="exolifeText" style={{ fontFamily: "" }}>
                MetaSoul® LIVE
              </div>
            </div>
            <div
              className="secretText"
              onClick={() => {
                props.setSpeedUp(false);
                setSecretBox(true);
              }}
              onMouseEnter={() => {
                props.setSpeedUp(true);
              }}
              onMouseLeave={() => {
                props.setSpeedUp(false);
              }}
            >
              What's Your Secret?
            </div>
          </div>
        ) : (
          <SecretInputPage
            waiting={waiting}
            setSecret={setSecret}
            secret={secret}
            error={error}
            handlesecret={handlesecret}
            setSpeedUp={props.setSpeedUp}
          />
        ))}

      {selectedMode === 2 && !question0 && (
        <div
          className="centerText"
          style={{
            opacity: waiting && 0.5,
            pointerEvents: waiting && "none",
          }}
        >
          <div className="lableText">
            Great, we found a cocoon; it will takes 5 minutes to create your
            MetaSoul®
          </div>
        </div>
      )}
      {showQuestion && <div
        className="centerText"
        style={{
          opacity: waiting && 0.5,
          pointerEvents: waiting && "none",
        }}
      >
        <div className="lableText">
          Are you intended to use this for car or something else?
        </div>
        <QuizOptionButton
          index={0}
          setSpeedUp={props.setSpeedUp}
          handleOnClick={() => {
            setSelectedMode(1)
            setShowQuestion(false);
          }}
          data={"For Car"}
        />
        <QuizOptionButton
          index={1}
          setSpeedUp={props.setSpeedUp}
          handleOnClick={() => {
            setSelectedMode(2)
            setShowQuestion(false)
          }}
          data={"Something Else"}
        />
      </div>}
      {selectedMode === 1 && <CarQuestionForm epuId={epuId} secret={secret} />}
      {selectedMode === 2 && questionIndex !== -1 && !lastMessage && question0 && (
        <div
          className="centerText"
          style={{
            opacity: waiting && 0.5,
            pointerEvents: waiting && "none",
          }}
        >
          <div className="lableText">
            {Array.isArray(lableText.que) ? question : lableText.que}
          </div>
          {lableText.warning && (
            <div className="warningText">{lableText.warning}</div>
          )}
          {lableText.ans.length > 0 ? (
            lableText.ans.map((data, index) => {
              return (
                <QuizOptionButton
                  index={index}
                  setSpeedUp={props.setSpeedUp}
                  handleOnClick={handleOnClick}
                  data={data}
                />
              );
            })
          ) : (
            <div className="quizecontainer">
              <div className="btnwrapper">
                <div className="optionButton speakbtnwrapper">
                  <button
                    className="option2"
                    style={
                      speechCount == 3
                        ? { pointerEvents: "none", padding: "10px" }
                        : { padding: "10px" }
                    }
                    disabled={speechCount == 3 || stopbtn || audioRunning}
                    onClick={async () => {
                      if (btnState) {
                        setRecordFlag(true);
                        await recognize();
                        if (questionIndex === 0) {
                          myTimeout = setTimeout(myTimer, 180000);
                        } else {
                          myTimeout = setTimeout(myTimer, 240000);
                        }
                      } else {
                        clearTimeout(myTimeout);
                        stopRecognize();
                      }
                    }}
                  >
                    {btnWaiting ? (
                      loader()
                    ) : audioRunning ? (
                      <div>
                        <span>Audio Playing...</span>
                      </div>
                    ) : btnState ? (
                      <div>
                        <span>Speak</span>
                      </div>
                    ) : (
                      <div>
                        <span>Stop</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
              <div className="btnwrapper">
                {isValidAnswer && (
                  <div className="optionButton speakbtnwrapper">
                    <button
                      className="option1 speakNextBtn"
                      disabled={!isValidAnswer}
                      onClick={() => {
                        submitApi(answer1);
                      }}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
              <div className="progress">
                {!answer1 && updatedText.length === 0 && !waiting && noAws && (
                  <ValidationError
                    text={
                      questionIndex == 0
                        ? "Minimum 25 words are required please click on Speak and answer the questions."
                        : "Minimum 150 words are required please click on Speak and answer the questions."
                    }
                  />
                )}
                {answer1 && !waiting && !isValidAnswer && (
                  <ValidationError
                    text={
                      questionIndex == 0
                        ? "Minimum 25 words are required."
                        : "Minimum 150 words are required."
                    }
                  />
                )}
                {answer1 && !waiting && speechCount == 2 && (
                  <ValidationError text="You have remain Last attempt for Speech" />
                )}
                {answer1 && !waiting && speechCount == 3 && (
                  <ValidationError text="You have reached your max limit of 3" />
                )}

                {progress()}
              </div>
              {audioEmotion && Object.keys(audioEmotion).length
                ? Object.keys(audioEmotion).map((emotion) => (
                  <AudioEmotion
                    audioEmotion={audioEmotion}
                    emotion={emotion}
                  />
                ))
                : null}
            </div>
          )}
        </div>
      )}
      {lastMessage && (
        <Video
          customwaveFunction={customwaveFunction}
          secret={secret}
          epuId={epuId}
          reply={reply}
        />
      )}
    </div>
  );
}
