import { Input } from "antd";
import React from "react";
import "../assets/css/menuQuestion.css";

const SecretInputPage = (props) => {
  const { waiting, setSecret, secret, error, handlesecret } = props;

  return (
    <div className="secretwrap">
      <div
        className="secretTextBox"
        style={{
          opacity: waiting && 0.5,
          pointerEvents: waiting && "none",
        }}
      >
        <Input
          placeholder="What's Your Secret?"
          className="secretInput"
          value={secret}
          onChange={(e) => {
            setSecret(e.target.value);
          }}
        />
        {error && <p className="redcolor">{error}</p>}
        <div className="optionButton fullWidth">
          <button
            className="option1 secretNextBtn"
            onClick={handlesecret}
            onMouseEnter={() => {
              props.setSpeedUp(true);
            }}
            onMouseLeave={() => {
              props.setSpeedUp(false);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecretInputPage;
