import React from "react";
import "../assets/css/menuQuestion.css";

const Video = (props) => {
  const { reply, secret, epuId, customwaveFunction } = props;

  return (
    <div
      className="videoContainer"
      onClick={() => {
        window.open(
          `https://metasoul.live/soulViewer.min.html?secret=${secret}&epuId=${epuId}`
        );
        customwaveFunction();
      }}
    >
      <video className="iframevideo" loop autoplay="autoplay">
        <source
          src={`https://emoshape-1e72.kxcdn.com/emosparks/${reply}.mp4?autoplay=1`}
          type="video/mp4"
        ></source>
      </video>
    </div>
  );
};

export default Video;
