import React from "react";
import "../assets/css/menuQuestion.css";

const ValidationError = (props) => {
  const { text } = props;

  return <p className="red">{text}</p>;
};

export default ValidationError;
