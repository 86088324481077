import React from "react";
import "../assets/css/menuQuestion.css";

const AudioEmotion = (props) => {
  const { emotion, audioEmotion } = props;
  return (
    <div className="emotionwrap">
      <div className="emotion">{emotion}</div>
      <div>{audioEmotion[emotion]}</div>
    </div>
  );
};

export default AudioEmotion;
