import { Progress } from "antd";
import React from "react";
import "../assets/css/menuQuestion.css";

const ProgressBar = (props) => {
  const { percentage, questionIndex } = props;

  return (
    <div className="progress progressWrapper">
      <span>0</span>
      <Progress
        showInfo={false}
        className="progressComponent"
        percent={percentage}
        strokeColor={{
          "0%": "red",
          "100%": "#87d068",
        }}
      />
      <span>{questionIndex == 0 ? 100 : 500}</span>
    </div>
  );
};

export default ProgressBar;
