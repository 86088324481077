import React from "react";
import "../assets/css/menuQuestion.css";

const QuizOptionButton = (props) => {
  const { index, handleOnClick, data } = props;

  return (
    <div className="optionButton">
      <button
        className={`option${index + 1}`}
        onClick={async () => {
          await handleOnClick(index + 1);
        }}
      >
        {data}
      </button>
    </div>
  );
};

export default QuizOptionButton;
