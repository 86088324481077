import { Input, Select } from "antd";
import axios from "axios";
import { Fragment, useState } from "react";
import "../assets/css/carQuestion.css";
import { carQuestions } from "../utils/data";

const initialValues = {
  drivingMode: "T",
};

carQuestions.forEach((question) => {
  initialValues[question.id] = "";
});

const CarQuestionForm = (props) => {
  const [answer, setAnswer] = useState(initialValues);

  const handleChange = (value) => {
    setAnswer((prevState) => {
      return {
        ...prevState,
        drivingMode: value,
      };
    });
  };

  const handleSubmit = async () => {
    const {
      data: { data },
    } = await axios.get("https://emohuman.com/location");
    const description = `Car ${answer.brand} ${answer.model} is a car owned by ${answer.name} is ${answer.color} color, and it was manufactured in ${answer.year}; it has ${answer.mileage}; I am located in ${data.city_name}, ${data.region_name}. The car receives regular ODBII information that it can interpret in realtime when received and help the user with an instant sentient diagnostic.`;
    console.log(description, "DESCRIPTION_NEW");
    let personalityResponse = await axios.post(
        `${process.env.REACT_APP_END_POINT}/get_personality`,
      {
        epuid: props.epuId,
        persona: `XXXX-X`,
        description: description,
        secret: props.secret,
      }
    );
    if (personalityResponse.status == 200) {
      window.open(
        `https://metasoul.live/soulViewer.min.html?secret=${props.secret}&epuId=${props.epuId}&persona=${answer.drivingMode}`,
        "_self"
      );
      setAnswer(initialValues);
    }
  };

  return (
    <div className="carQuestionContainer">
      <Fragment>
        {carQuestions?.map((carQue) => (
          <div className="formControl" key={carQue.id}>
            <p>{carQue.que}</p>
            <Input
              placeholder={carQue.plc}
              className="formInput"
              value={answer[carQue.id]}
              onChange={(e) => {
                setAnswer((prevState) => {
                  const newState = { ...prevState };
                  newState[carQue.id] = e.target.value;
                  return newState;
                });
              }}
            />
          </div>
        ))}
        <div className="formControl">
          <p>What driving mode you prefer?</p>
          <Select
            defaultValue={initialValues.drivingMode}
            className="selectBox"
            dropdownStyle={{
              borderRadius: "20px",
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
            onChange={handleChange}
            options={[
              { value: "T", label: "Sports" },
              { value: "A", label: "Comfort" },
            ]}
          />
        </div>
        <button className="nextBtn" onClick={handleSubmit}>
          Submit
        </button>
      </Fragment>
      {/* {!isGeolocationAvailable && <h2>Location API not supported</h2>} */}
      {/* {!isGeolocationEnabled && <Fragment>
            <h2>Location Permission not granted!</h2>
            <p>This part of website requires your overall location information (Country and State). So allow this website to get permission from browser settings and after granting the permission, click on "Get Location" button. or Input Your Location manually.</p>
            <button className={classes.nextBtn}>Get Location</button>
            <button className={classes.nextBtn}>Input Location Manually</button>
        </Fragment>} */}
    </div>
  );
};

export default CarQuestionForm;
