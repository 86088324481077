import React from "react";
import "../assets/css/menuQuestion.css";

const Loader = (props) => {
  return (
    <div class="infinity-loader">
      <div class="bg">
        <div class="left-bg"></div>
        <div class="right-bg"></div>
      </div>
      <div class="fg">
        <div class="top-left-rect">
          <div></div>
        </div>
        <div class="bottom-right-rect">
          <div></div>
        </div>
        <div class="top-right-rect">
          <div></div>
        </div>
        <div class="bottom-left-rect">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
