const myQuestions = [
  {
    que: "Describe yourself or who you would like your MetaSoul® to incarnate. For example, you could say: “John Smith, he lives in New York City...” and add his motivations, stage in life, and hobbies. Again, use knowledge or your imagination.",
    warning: "This information will be visible in MetaSoul® Public Page",
    ans: [],
  },
  {
    que: [
      "How is your MetaSoul® feeling today?",
      "Tell us about her hobbies.",
      "What makes her happy in life",
      "What would you like your MetaSoul® to change in the world?",
      "How does your MetaSoul® feel seeing other people cry?",
      "What would you like your MetaSoul® to accomplish in the world?",
      "Thank you you can press Stop now!",
    ],
    warning:
      "All information will be strictly confidential and erased after few minutes",
    ans: [],
  },
  {
    que: "You are at McDonald’s and order a chicken legend burger with mayonnaise, but they give you one with salsa?",
    ans: [
      "Angrily refuse the burger and insist on seeing the manager to complain about the poor service.",
      "Call the waiter and indicate you ordered your burger with mayonnaise.",
    ],
  },
  {
    que: "You are a customer waiting in line to be served. Suddenly, someone steps in line ahead of you?",
    ans: [
      "Pull the person out of line and make them go to the back.",
      "Indicate to the person that you are in line and point out where it begins.",
    ],
  },
  {
    que: "After walking out of a store where you purchased some items, you discover you were short-changed.",
    ans: [
      "Go to the manager and indicate how you were cheated by the clerk, then demand the proper change.",
      "Return to the clerk and inform them of the error.",
    ],
  },
];

const carQuestions = [
  { que: "Who is the owner of the car?", plc: "Enter Name", id: "name" },
  { que: "What is the Brand of Car?", plc: "Enter Car Brand", id: "brand" },
  { que: "What is the model of Car?", plc: "Enter Car Model", id: "model" },
  { que: "In which year car is launched?", plc: "Enter Launch Year", id: "year" },
  { que: "What is the Color of Car?", plc: "Enter Car Color", id: "color" },
  { que: "How many Kms or Miles car have been driven?", plc: "Enter Value with unit", id: "mileage" }
]

export default myQuestions;

export { carQuestions };

